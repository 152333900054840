import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import style from './linkDisabled.module.css';

export default function LinkDisabled(): ReactElement {
  const [open, setOpen] = useState(false);
  const { propertySlug } = useParams<{
    propertySlug: string;
    registrationId: string;
  }>();

  const {
    data: propertyDetail,
    loading: queryLoading,
    error,
  } = useProperty({ propertySlug: propertySlug || '' });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={style.container}>
      <div className={`${style.box} white noShadow`}>
        <div className={style.passForm}>
          <h1 className={`${style.subHeaderTitle} textColorBlack`}>
            Get your access passes.
          </h1>
          <div className={`${style.mainBox}`}>
            {Boolean(error) ? (
              <>
                <div>Something seems to be wrong with this link. </div>
                <div>
                  To get an access pass for this property, please reach out to the host
                  directly for a new registration link.
                </div>
              </>
            ) : (
              <>
                <div>
                  We apologize. The host has disabled the ability for guests to request
                  their own passes.
                </div>
                <div>
                  To get an access pass for this property, please reach out to the host
                  directly with the details provided with your reservation.
                </div>
              </>
            )}
            {Boolean(propertyDetail) && (
              <div className={`${style.innerBox}`}>
                <div className={style.column}>
                  <div className={style.title}>Property Details</div>
                  <div>
                    {`${propertyDetail?.address}, ${propertyDetail?.city}, ${propertyDetail?.state}, ${propertyDetail?.zipCode}`}
                  </div>
                </div>
                {propertyDetail?.enableContactDetails && (
                  <div className={style.column}>
                    <div className={style.title}>Host Details</div>
                    <div>{propertyDetail?.propertyManagerName}</div>
                    <div className={style.hostDetails}>
                      <div>{propertyDetail?.propertyManagerPhone}</div>
                      <Button id="demo-customized-button" onClick={handleClickOpen}>
                        Call
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Call Host</DialogTitle>
        <DialogContent>
          <DialogContentText>
            you can call to host by using {propertyDetail?.propertyManagerPhone}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
