import { gql, useQuery } from '@apollo/client';

interface IProps {
  propertySlug: string;
}

interface PropertyData {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  propertyManagerPhone: string;
  propertyManagerName: string;
  hostPay: boolean;
  guestPay: boolean;
  rentalUnitId: string;
  userId: string;
  enableContactDetails: boolean;
  hostPropertySlug: string | null;
  guestPropertySlug: string | null;
}

interface IPropertyDetails {
  data?: PropertyData;
  error?: string;
  loading: boolean;
}

const GET_PROPERTY = gql`
  query getPropertyBySlug($propertySlug: String!) {
    getPropertyBySlug(propertySlug: $propertySlug) {
      success
      error
      data {
        address
        city
        state
        zipCode
        propertyManagerPhone
        propertyManagerName
        hostPay
        guestPay
        hostPropertySlug
        guestPropertySlug
        rentalUnitId
        userId
        enableContactDetails
        hostPropertySlug
        guestPropertySlug
      }
    }
  }
`;

export default function useProperty({ propertySlug }: IProps): IPropertyDetails {
  const { data, error, loading } = useQuery(GET_PROPERTY, {
    variables: { propertySlug },
    fetchPolicy: 'cache-and-network',
    skip: !propertySlug,
  });

  return {
    data: data?.getPropertyBySlug?.data?.[0],
    error: error?.message || data?.getPropertyBySlug?.error,
    loading,
  };
}
