import { gql, useMutation, useQuery } from '@apollo/client';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../../common_lib_front/utilities/passCredOrNumber';
import { OrderSummary } from '../../../components/orderSummary/orderSummary';
import { PassImageDisplay } from '../../../components/passGenerator/passGenerator';
import PassSummaryCollection from '../../../components/passSummary/passSummaryCollection';
import RegistrationNavHeader from '../../../components/registrationNavHeader/registrationNavHeader';
import ReceiptDownload from '../receiptDownload/receiptDownload';
import { RegistrationPageProps } from '../wrapper/wrapper';
import style from './receipt.module.css';

const RECEIPT_QUERY = gql`
  query Step4Query($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        registrationId
        number
        externalCredentialNumber
        startDate
        endDate
        status
        url
        purchasedBy
        userId
      }
    }
  }
`;

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
      }
    }
  }
`;

export const EDIT_STEP_NUM = gql`
  mutation Step2($stepNumber: Float!, $registrationId: String!) {
    editRegistrationStepNumber(stepNumber: $stepNumber, registrationId: $registrationId) {
      success
      error
    }
  }
`;

export default function Receipt(props: RegistrationPageProps): ReactElement {
  const { nextHref } = props;
  const [passes, setPasses] = useState<PassInfo[]>([]);
  const { registrationId } = useParams<{ registrationId: string }>();
  const { t } = useTranslation();
  const { name: communityName, communityId } = useContext(CommunityContext);
  const location = useLocation();
  const [popup, setPopup] = useState<undefined | ReactElement>(undefined);
  const [showSummary, setShowSummary] = useState<boolean>(false);

  // const { data: passUrls } = useGetPassImage(
  //   useMemo(() => passes.map(p => p.passId), [passes]),
  // );

  // set popup from url hash args
  useEffect(() => {
    if (location.hash.includes('download')) {
      setPopup(<ReceiptDownload passes={passes} />);
      return;
    }
    setPopup(undefined);
  }, [location.hash, setPopup, passes]);

  const { refetch } = useQuery(RECEIPT_QUERY, {
    variables: { registrationId },
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        // setPasses(passesList1);
      }
    },
    onCompleted: d => {
      setShowSummary(
        d.getPassesByRegistration.data.some((val: any) => val.purchasedBy === val.userId),
      );
      const res = d.getPassesByRegistration.data.map((p: any) => {
        return newPassInfo({
          ...p,
          paid: p.purchasedBy !== p.userId,
        });
      });
      Promise.all(
        res.map((element: any, idx: number) =>
          backendClient
            .query({
              query: GET_VEHICLE,
              variables: { passId: element.passId },
            })
            .then(v => {
              res[idx].vehicle = newVehicleInfo(v.data.getVehicle.data);
            })
            .catch(e => {
              console.log(e);
            }),
        ),
      ).then(() => {
        setPasses(res);
      });
    },
  });

  const [doEditStep] = useMutation(EDIT_STEP_NUM, {
    variables: {
      registrationId,
      stepNumber: 5,
    },
  });
  useEffect(() => {
    doEditStep();
  }, [doEditStep]);

  if (popup !== undefined) {
    return <ReceiptDownload passes={passes} />;
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          title={
            communityName === 'Grand Complex'
              ? 'Thanks for your purchase!'
              : t(`Thank you for registering at ${communityName}!`)
          }
          subtitle={t('We hope you enjoy your stay!')}
        />
        <div className={style.flexBox}>
          <div className={showSummary ? style.boxLeft : style.boxCenter}>
            <OrderSummary
              passNumbers={passes.map(elem => passCredOrNumber(elem))}
              date={formatDate(new Date())}
              total={
                showSummary
                  ? passes.reduce(
                      (sum, val) =>
                        ['paid', 'ach-pending'].includes(val.paid)
                          ? sum
                          : sum + val.price,
                      0,
                    )
                  : undefined
              }
              method={showSummary ? 'Card' : undefined}
              data={passes}
              refetch={refetch}
            />
            <div style={{ marginTop: '5px' }} />

            {/* {passes.map((p: PassInfo) => {
              console.log(p, p.url);
              return (
                <img
                  key={p.number}
                  src={`https://access-pass.s3.us-west-2.amazonaws.com/${communityId}/${p.number}.png`}
                  alt={`Could not display Access Pass ${p.number}. Please print this pass from 'My Passes'`}
                  className={style.passDisplay}
                />
              );
            })} */}
            {passes
              .filter(p => p.status !== 'incomplete-rental-car')
              .map(p => (
                <PassImageDisplay
                  key={p.passId}
                  passId={p.passId}
                  hideImage={communityId === 'watercolor'}
                  clickDownload
                  showDownloadBtn
                  showPrintBtn
                />
              ))}
          </div>
          {showSummary && (
            <div className={style.boxRight}>
              <PassSummaryCollection
                showVehicleInfo
                showEditBtn={false}
                showTotal
                data={passes}
              />
            </div>
          )}
        </div>

        <div className={style.btn}>
          <Link to={nextHref}>
            <GenericButton size="large" color="blue" title={t('Continue')} />
          </Link>
        </div>
      </div>
    </div>
  );
}
