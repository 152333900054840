import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { backendResponse } from '../types/backendResponse';
import { backendClient } from '../utilities/BackendAPI';

/**
 * get
 */
const GET_PAYMENT_SESSION = gql`
  query Query($paymentSessionId: String!) {
    getPaymentSession(paymentSessionId: $paymentSessionId) {
      success
      error
      data {
        registrationIds
        paymentSessionId
        createdBy
        checkoutSessionId
        guests {
          registration {
            registrationId
          }
          communityRental {
            address
            arrivalDate
            departureDate
          }
          guest {
            firstName
            lastName
            email
            phoneNumber
          }
          passes {
            passId
            passInfoId
            endDate
            startDate
            paid
            status
          }
          guestInfo {
            email
            firstName
            lastName
            phoneNumber
          }
          reservationExtension {
            reservationExtensionId
            newStartDate
            newEndDate
            fulfilled
            registrationId
          }
        }
      }
    }
  }
`;

type GET_PAYMENT_SESSION_VARS = {
  paymentSessionId: string;
};
type GET_PAYMENT_SESSION_RES = {
  getPaymentSession: backendResponse<{
    registrationIds: string;
    paymentSessionId: string;
    createdBy: string;
    checkoutSessionId: string;
    guests: {
      registration: {
        registrationId: string;
      };
      communityRental: {
        address: string;
        arrivalDate: string;
        departureDate: string;
      };
      guest: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
      };
      guestInfo: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
      };
      reservationExtension: {
        reservationExtensionId: string;
        newStartDate: string | null;
        newEndDate: string | null;
        fulfilled: boolean;
        registrationId: string;
      } | null;
      passes: {
        passId: string;
        passInfoId: string;
        endDate: string;
        startDate: string;
        paid: string;
        status: string;
      }[];
    }[];
  }>;
};

type usePaymentSessionOptions = QueryHookOptions<
  GET_PAYMENT_SESSION_RES,
  GET_PAYMENT_SESSION_VARS
>;
export const usePaymentSession = (options: usePaymentSessionOptions) =>
  useQuery<GET_PAYMENT_SESSION_RES, GET_PAYMENT_SESSION_VARS>(
    GET_PAYMENT_SESSION,
    options,
  );

/**
 * Create
 */
const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSession(
    $newPaymentSession: InsertPaymentSessionInput!
    $guestFlow: Boolean
    $userId: String
  ) {
    createPaymentSession(
      newPaymentSession: $newPaymentSession
      guestFlow: $guestFlow
      userId: $userId
    ) {
      success
      error
      data {
        paymentSessionId
      }
    }
  }
`;

type CREATE_PAYMENT_SESSION_VARS = {
  newPaymentSession: {
    registrationIds: string[];
  };
  userId: string;
  guestFlow: boolean;
};
type CREATE_PAYMENT_SESSION_RES = {
  createPaymentSession: backendResponse<{
    paymentSessionId: string;
  }>;
};
export const createPaymentSession = async (vars: CREATE_PAYMENT_SESSION_VARS) =>
  backendClient.mutate<CREATE_PAYMENT_SESSION_RES, CREATE_PAYMENT_SESSION_VARS>({
    mutation: CREATE_PAYMENT_SESSION,
    variables: vars,
  });
