function imageToPrint(sourcees: string[]): string {
  return `
<html>
  <head>
    <script>
      function step2() {
        window.print();
        window.close();
      }
      function step1() {
        setTimeout("step2()", 10);
      }
    </script>
    <style>
      img {
        page-break-after: always;
        max-height: 8in;
      }
    </style>
  </head>
  <body onload='step1()'>
    ${sourcees.map(src => `<img src=${src} />`)}
  </body>
</html> 
    `;
}
// <img src=${source} />
// '<html><head><script>' +
// 'function step1(){\n' +
// "setTimeout('step2()', 10);}\n" +
// 'function step2(){window.print();window.close()}\n' +
// '</scri' +
// "pt></head><body onload='step1()'>\n" +
// "<img src='" +
// source +
// "' /></body></html>"

export function printImageFile(urls: string[]): void {
  const pageLink = 'about:blank';
  const pwa = window.open(pageLink, '_new');
  if (!pwa) throw Error('Could not open link');
  pwa?.document.open();
  pwa?.document.write(imageToPrint(urls));
  pwa?.document.close();
}
