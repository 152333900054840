import { editPassData, PassInfosWithVehicles, PassWithVehicles } from './types';

export function mapPassIdsToVehicles(
  formattedPasses: Record<string, any[]>,
  passInfos: PassInfosWithVehicles,
  propertySlug: string,
  otherValuesForEveryVehicle: Record<string, string> = {},
): PassWithVehicles[] {
  const result: any[] = [];
  Object.keys(formattedPasses).forEach(key => {
    const passes = formattedPasses[key];
    console.log('key', key);
    console.log('passInfos', passInfos);

    const vehicles = passInfos[key] || [];
    const filteredVehicles = vehicles.filter(vehicle => !vehicle.dontKnowLicense);
    passes.forEach((pass, index) => {
      if (filteredVehicles.length > 0) {
        const vehicle = filteredVehicles[index];
        result.push({
          passId: pass.passId,
          passInfoId: pass.passInfoId,
          vehicleInfo: {
            ...otherValuesForEveryVehicle,
            make: vehicle?.make,
            type: vehicle?.type,
            licensePlate: vehicle?.licensePlate,
            color: vehicle?.color,
            vehicleModel: vehicle?.vehicleModel,
            fleetNumber: vehicle?.fleetNumber,
            licensePlateState: vehicle?.licensePlateState,
            primaryDriverName: vehicle?.primaryDriverName,
            licensePrimaryDriver: vehicle?.licensePrimaryDriver,
          } as Partial<editPassData>,
          propertySlug: propertySlug,
        });
      }
    });
  });
  return result;
}
